/* App.css */
.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  padding: 2rem;
  background: #f5f5f5; /* Light grey background */
}

/* This will center your app and give it a max-width */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: white; /* White background for the content */
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box-shadow for a subtle depth effect */
}

/* Style the form inputs */
input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: calc(100% - 22px); /* Full-width inputs */
}

/* Style the form */
form {
  margin-bottom: 20px;
}

/* Toggle switch styles are good from your previous code */

/* Style the button */
button {
  background-color: #2196F3; /* Blue background */
  color: white; /* White text */
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1rem;
}

button:hover {
  background-color: #1976D2; /* Darker blue on hover */
}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd; /* Light grey border */
}

th {
  background-color: #f5f5f5; /* Light grey background */
}

tr:nth-child(even) {
  background-color: #f9f9f9; /* Zebra striping for rows */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .form-container, .amortization-table, .container {
    flex-direction: column;
  }

  input, button {
    width: 94%; /* Adjust width for small screens */
  }
}

.loan-form label {
  display: block;
  margin: 10px 0;
}

.loan-form input[type="range"] {
  width: 100%;
  margin: 5px 0;
}

.monthly-payment {
  margin: 20px 0;
  font-size: 1.5em;
  font-weight: bold;
}

.download-button {
  background-color: red;
  color: white;
}

/* Add these styles to your CSS file */

.modal form {
  display: flex;
  flex-direction: column;
}

.modal label {
  margin-bottom: 0.5rem;
}

.modal input,
.modal select {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal button[type="submit"] {
  background-color: #4CAF50; /* Accent color */
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.content.blur {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Higher than other content but lower than modal for interaction */
}



.modal {
  background: white;
  padding: 3rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px; /* Fixed max-width for larger screens */
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.close-modal {
  background: none;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1rem;
  color: #aaa; /* Subtle color */
  cursor: pointer;
}

.modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal label {
  font-size: 1rem;
  color: #333;
}

.modal input,
.modal select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.modal input[type="text"],
.modal input[type="email"],
.modal input[type="number"],
.modal select {
  background-color: #f8f8f8;
  transition: all 0.3s ease-in-out;
}

.modal input[type="text"]:focus,
.modal input[type="email"]:focus,
.modal input[type="number"]:focus,
.modal select:focus {
  border-color: #4CAF50;
  background-color: white;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}

button[type="submit"] {
  padding: 0.75rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #43a047;
}

/* App.css */

.table-container {
  max-height: 500px; /* Adjust based on the row height to show only 10 rows */
  overflow: hidden; /* Hide the rest of the table */
  position: relative;
  margin-bottom: 20px; /* Space for the 'View More' button or similar action */
}

.table-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; /* Height of the blur effect */
  background: linear-gradient(to bottom, transparent, #fff);
}

.table-container.blur::after {
  opacity: 1; /* When the table is scrolled to the end, show the gradient */
}

.radio-group {
  display: flex;        /* Use flexbox to layout children */
  justify-content: space-between; /* Space out children evenly */
  margin: 1rem 0;       /* Add some margin around the group for spacing */
}

/* Styles for individual radio button labels */
.radio-group label {
  flex-basis: 98%;
}

/* Assuming you have a class 'form-modal' for the modal container */
.form-modal {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto; /* Adjust as needed */
  max-width: 500px; /* Prevent the modal from becoming too wide */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between each form row */
  align-items: stretch; /* Align children width to stretch across the modal width */
}

.form-modal .form-row {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between label and input/select */
  margin-bottom: 1rem;
}

.form-modal label {
  flex-basis: 98%;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.25rem; /* Additional spacing from label to input/select */
}

.form-modal .form-field {
  margin-bottom: 1rem;
}

.form-modal .form-field input,
.form-modal .form-field select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-modal .close-modal {
  align-self: flex-end;
  margin-bottom: 0.5rem;
}

.form-modal button[type="submit"] {
  background-color: #4CAF50; /* Accent color */
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-modal button {
  background-color: #4CAF50; /* Replace with your accent color */
  color: white;
  padding: 0.05rem 0.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-modal button:hover {
  background-color: #43a047; /* A shade darker for hover state */
}

/* Accessibility */
.form-modal .form-field input:focus,
.form-modal .form-field select:focus {
  outline: 2px solid #4CAF50; /* Replace with your accent color */
  outline-offset: 2px;
}

/* Animation and feedback */
.form-modal .form-field input:invalid,
.form-modal .form-field select:invalid {
  border-color: #e53935; /* A red color for validation errors */
}

.radio-group1 {
  display: flex; /* Use flexbox to layout children inline */
  justify-content: flex-start; /* Align children to the start */
  gap: 15px; /* Space between the radio buttons */
}

/* Styles for individual radio button labels */
.radio-group1 label {
  display: flex;
  align-items: center; /* Align the label text with the radio button */
  margin-right: 15px; /* Add space to the right of each label */
}

/* Ensure that the input fields (radio buttons) align properly */
.radio-group1 input[type="radio"] {
  margin-right: 5px; /* Space between the radio button and its label */
}
/* Background blur effect */
.background-blur {
  filter: blur(5px);
}

.interest-rate-slider {
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0; /* Start with max-height 0 to collapse the element */
  opacity: 0; /* Start with opacity 0 to make the element transparent */
}

.interest-rate-slider.visible {
  max-height: 100px; /* Provide enough space for the slider to be fully visible */
  opacity: 1; /* Make the slider fully opaque */
}

@media (min-width: 768px) {
  .form-modal {
    max-width: 600px; /* Adjust the max-width as needed */
  }
}
